import React from 'react'
import { LayoutIR } from '../../../components/organisms/Layout/LayoutIR'
import { SEOIR } from '../../../components/data/SEO'
import { Strength } from '../../../components/organisms/Strength/Strength'
import { ENGLISH_TITLE } from '../../../components/data/English'

const Page = () => {
  return (
    <LayoutIR
      heading="CRGグループの強み"
      headingEnglish={ENGLISH_TITLE.OurStrengths}
      BreadcrumbData={[
        { title: '株主・投資家の皆さまへ', url: '/ir' },
        { title: '経営方針', url: '/ir/policy' },
        { title: 'CRGグループの強み', url: '/' },
      ]}
    >
      <Strength />
    </LayoutIR>
  )
}

export default Page

/**
 * headタグの内容
 */
/** */
export const Head = () => (
  <SEOIR title="CRGグループの強み" url="/ir/policy/strength" />
)
