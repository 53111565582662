import { strength } from '../../styles/strength'

export const Strength = () => {
  return (
    <div css={strength}>
      {/* アイテム01 */}
      <div className="pc:flex pc:items-center white pc:px-8 pc:py-12 mb-20 pc:mb-16">
        <div className="pc:order-2 pc:ml-12 item-img">
          <img className="mb-6" src="/common/img/st-01.png" alt="" />
        </div>
        <div className="pc:order-1 item-text">
          <p className="num text-lg mb-4">01</p>
          <h2 className="text-xxl font-bold leading-tight mb-4">
            CRGグループ
            <br />
            ならではの<span>総合力</span>
          </h2>
          <p className="leading-normal text-md">
            グループ全体のシナジー効果を活かし、人材分野で様々な課題を抱えているお客様へサービスを提供することが可能です。
          </p>
        </div>
      </div>

      {/* アイテム02 */}
      <div className="pc:flex pc:items-center bg-white pc:px-8 pc:py-12 mb-20 pc:mb-16">
        <div className="pc:mr-12 item-img">
          <img className="mb-6" src="/common/img/st-02.png" alt="" />
        </div>
        <div className="item-text">
          <p className="num text-lg mb-4">02</p>
          <h2 className="text-xxl font-bold leading-tight mb-4">
            <span>人材業界20年</span>の<br />
            実績
          </h2>
          <p className="leading-normal text-md">
            派遣事業を開始して20年以上。長年の蓄積したノウハウで人材課題や業務効率化などの人事課題を解決した実績がございます。
          </p>
        </div>
      </div>

      {/* アイテム03 */}
      <div className="pc:flex pc:items-center bg-white pc:px-8 pc:pt-12 mb-20 pc:mb-16">
        <div className="pc:order-2 pc:ml-12 item-img">
          <img className="mb-6" src="/common/img/st-07.png" alt="" />
        </div>
        <div className="pc:order-1 item-text">
          <p className="num text-lg mb-4">03</p>
          <h2 className="text-xxl font-bold leading-tight mb-4">
            <span>スピーディな</span>
            <br />
            人材手配
          </h2>
          <p className="leading-normal text-md">
            全国数十万人の登録スタッフの中から適切な人材をスピーディに手配します。
          </p>
        </div>
      </div>

      {/* アイテム04 */}
      <div className="pc:flex pc:items-center bg-white pc:px-8 pc:py-12 mb-20 pc:mb-16">
        <div className="pc:mr-12 item-img">
          <img className="mb-6" src="/common/img/st-08.png" alt="" />
        </div>
        <div className="item-text">
          <p className="num text-lg mb-4">04</p>
          <h2 className="text-xxl font-bold leading-tight mb-4">
            <span>的確な</span>フォロー
          </h2>
          <p className="leading-normal text-md">
            派遣スタッフへの定期的な面談を行い、継続率の向上を実現。お客様にも定期的なフォローアップを行い、継続的な取引を実現しています。
          </p>
        </div>
      </div>

      {/* アイテム05 */}
      <div className="pc:flex pc:items-center bg-white pc:px-8 pc:py-12 mb-20 pc:mb-16">
        <div className="pc:order-2 pc:ml-12 item-img">
          <img className="mb-6" src="/common/img/st-05.png" alt="" />
        </div>
        <div className="pc:order-1 item-text">
          <p className="num text-lg mb-4">05</p>
          <h2 className="text-xxl font-bold leading-tight mb-4">
            <span>
              業務効率化サポート
              <br className="pc:hidden" />
              の実績
            </span>
            を活かした
            <br className="pc:hidden" />
            HRテック
          </h2>
          <p className="leading-normal text-md">
            これまで培ってきた採用活動の効率化や業務の改善のノウハウとITを活かしたSaaS型採用業務効率化/改善サービスを提供。採用活動で様々な悩みを抱える企業様の課題を解決していきます。
          </p>
        </div>
      </div>

      {/* アイテム06 */}
      <div className="pc:flex pc:items-center bg-white pc:px-8 pc:py-12 mb-20 pc:mb-16">
        <div className="pc:mr-12 item-img">
          <img className="mb-6" src="/common/img/st-06.png" alt="" />
        </div>
        <div className="item-text">
          <p className="num text-lg mb-4">06</p>
          <h2 className="text-xxl font-bold leading-tight mb-4">
            <span>課題解決型</span>の<br />
            M＆A・投資実行
          </h2>
          <p className="leading-normal text-md">
            総合人材会社としてのこれまでの知見を活かした課題解決型のM＆A・仲介・投資を実施。シナジー効果の創出と中長期的な企業価値向上を目指します。
          </p>
        </div>
      </div>
    </div>
  )
}
