import { css } from '@emotion/react'

export const strength = css`
        
        .num {
            font-family: 'Rajdhani';
            line-height: 1;
            color: #CC050B;
        }

        h2 {
            span {
                color: #CC050B;
            }
        }

        @media screen and (min-width: 1199px) {
            h2 {
                font-size: 48px;
            }
            .gatsby-image-wrapper {
                margin-bottom: 0;
            }
            .item-img {
                width: 400px
            }
            .item-text {
                flex: 1;
            }
        }
    `